<template>
  <div class="parametrosstreaming">
    <tabla titulo="Parametros Streaming" 
          :headers = "headers"
          :arreglo = "ParametrosStreamingGetter.items"
            />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import tabla from '@/components/parametrosstreaming/';

export default {
  name: 'parametrosstreaming',

   components: {
    tabla
  },
  
  created(){
    this.ConsultarParametrosStreaming()
  },

  data: () =>{
    return{
    headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'PLATAFORMA', value: 'nombre' },
        { text: 'CÓDIGO', value: 'codigo' },
        { text: 'DURACIÓN', value: 'duracion' },
        { text: 'TIPO CUENTA', value: 'tipo_cuenta' },
        { text: 'DÍAS LIMITE', value: 'dias_antes' },
        { text: 'PRECIO PV', value: 'precio_pv' },
        { text: 'ACTIVO', value: 'active' },
        { text: 'ACCIONES', value: 'actions', sortable: false },
      ],

    }
  },

  methods: {
    ...mapActions(['ConsultarParametrosStreaming']),

  },

  computed:{
      ...mapGetters(['ParametrosStreamingGetter'])
  }
}
</script>
