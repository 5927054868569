<template>
    <div>
      <v-form
         v-on:submit.prevent="DialogAceptar = true"
          ref="form"
          lazy-validation
      >
        <v-card>
        <v-card-title>
          <span class="headline">{{Accion}}</span>
          <v-spacer></v-spacer>
          <span v-if="Item.id != 0" class="headline">ID: {{Item.id}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
             <v-row>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                    v-model="Item.cuenta"
                    :items="PlataformasStreamingGetter.items"
                    item-text="nombre"
                    item-value="id"
                    menu-props="auto"
                    label="Plataforma Streaming"
                    persistent-hint
                    ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="Item.codigo"
                  label="Código *"
                  hint="Código de la plataforma streaming"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="Item.duracion"
                  label="Duración *"
                  hint="Duración de la plataforma streaming"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="Item.tipo_cuenta"
                  label="Tipo de Cuenta *"
                  hint="Tipo de Cuenta de la Plataforma"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

             

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="Item.plural"
                  label="Mensaje tipo de cuenta *"
                  hint="Mensaje en plural que acompaña el tipo de cuenta"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="Item.dias_antes"
                  label="Días Limite *"
                  hint="Días de holgura para la venta del producto"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  auto-grow
                  counter
                  label="Mensaje del Producto"
                  v-model="Item.mje"
                  hint="Escriba el mensaje que aparecera en la venta del producto."
                  persistent-hint
                  required
                ></v-textarea>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="Item.costo"
                  label="Costo del Producto *"
                  hint="Escriba el Costo del producto"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="Item.precio_c"
                  label="Precio de Venta *"
                  hint="Escriba el precio que se le descontará al punto de venta"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="Item.precio_pv"
                  label="Precio Sugerido *"
                  hint="Escriba el precio sugerido de venta"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="Item.puntos"
                  label="Puntos *"
                  hint="Número de puntos que se asignaran"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="Item.porcentaje"
                  label="Porcentaje % *"
                  hint="Escribe el porcentaje que se dara de comisión"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="Item.porcentaje_comision"
                  label="Porcentaje % Con Membresia *"
                  hint="Escribe el porcentaje que se dara de comisión con membresia"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="Item.orden"
                  label="Orden *"
                  hint="Orden en que se mostrará el producto"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  v-model="Item.activo"
                  :items="SINO"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="El Producto está activo?"
                  hint="Indique si el producto se encuentra activo en el sistema"
                  persistent-hint
                  :rules="[v => v >= 0 || 'Item Requerido']"
                  required
                ></v-select>
              </v-col>

            </v-row>
          </v-container>

          <small>* indica campo requerido</small>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="Ocultar()">Cerrar</v-btn>
          <v-btn color="primary" type="submit">Guardar</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>

     <v-dialog v-model="DialogAceptar" persistent max-width="380">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{Accion}}
        </v-card-title>

        <v-card-text class="mt-4">
          <h2>¿Estas seguro de guardar?</h2>
        </v-card-text>
        
        <v-divider></v-divider>

        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="DialogAceptar = false">Cancelar</v-btn>
          <v-btn color="primary" @click="Guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </div>
</template>

<script>
import { mapState, mapGetters, mapActions} from 'vuex'

export default ({

  props:['Accion','Item','NuevoActivo'],

  watch:{
      Alerta(value){
        if(value.error == false){
          this.Ocultar()
        }
      }
  },

  created (){
    this.ObtenerPlataformasStreaming()
  },

  data: () => ({
    var: null,
    DialogAceptar: false,

    ItemEnviar: {
          id: 0,
          cuenta: null,
          codigo: null,
          duracion: null,
          tipo_cuenta: null,
          dias_antes: null,
          plural: null,
          mje: null,
          costo: null,
          precio_c: null,
          porcentaje_comision: null,
          precio_pv: null,
          porcentaje: null,
          puntos: null,
          orden: null,
          activo: 1,
          },
  }),

  methods: {
     ...mapActions(['NuevoParametrosStreaming', 'ConsultarParametrosStreaming','ObtenerPlataformasStreaming']),

    async Guardar (){

      this.ItemEnviar.id = this.Item.id
      this.ItemEnviar.cuenta = this.Item.cuenta
      this.ItemEnviar.codigo = this.Item.codigo
      this.ItemEnviar.duracion = this.Item.duracion
      this.ItemEnviar.tipo_cuenta = this.Item.tipo_cuenta
      this.ItemEnviar.dias_antes = this.Item.dias_antes
      this.ItemEnviar.plural = this.Item.plural
      this.ItemEnviar.mje = this.Item.mje
      this.ItemEnviar.costo = this.Item.costo
      this.ItemEnviar.precio_c = this.Item.precio_c
      this.ItemEnviar.porcentaje_comision = this.Item.porcentaje_comision
      this.ItemEnviar.precio_pv = this.Item.precio_pv
      this.ItemEnviar.porcentaje = this.Item.porcentaje
      this.ItemEnviar.puntos = this.Item.puntos
      this.ItemEnviar.orden = this.Item.orden
      this.ItemEnviar.activo = this.Item.activo

      await this.NuevoParametrosStreaming(this.ItemEnviar)
      await this.ConsultarParametrosStreaming()
      this.DialogAceptar = false
    },

    Ocultar(){
        this.$emit('NuevoActivo', false)
    }
  },

  computed:{
    ...mapState(['Alerta']),
    ...mapGetters(['SINO','PlataformasStreamingGetter'])
  }


})
</script>